import { Component } from "react";
import { ILayoutProps } from "./ILayoutProps";
import { ILayoutState } from "./ILayoutState";
import styles from "./Layout.module.scss";
import { ReactNotifications } from "react-notifications-component";
import Header from "../Header/Header";
import Footer from "../Footer/Footer";
import SocialMedia from "../SocialMedia/SocialMedia";

export default class Layout extends Component<ILayoutProps, ILayoutState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={styles.app}>
        <ReactNotifications />
        <Header></Header>
        <div className={["row", styles.bodyRow].join(" ")}>
          <div className="col-md-12">{this.props.children}</div>
        </div>
        <div>
          <div
            onClick={() => {
              window.location.href = "contactus";
            }}
            className={styles.enquiryNow}
          >
            Enquiry Now
          </div>
        </div>
        <SocialMedia></SocialMedia>
        <Footer></Footer>
      </div>
    );
  }
}
