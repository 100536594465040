import { IProductPagesProps } from "./IProductPagesProps";
import { IProductPagesState } from "./IProductPagesState";
import styles from "./ProductPages.module.scss";
import { Component } from "react";

export default class FIBCBags extends Component<
  IProductPagesProps,
  IProductPagesState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={["row", styles.container].join(" ")}>
          <div className={["col-md-12"].join(" ")}>
            <div className={styles.productImageContainer}>
              <img
                className={styles.productImg}
                src={this.props.imagePath}
              ></img>
            </div>
            <div>
              FIBC (Flexible Intermediate Bulk Container) bulk bags, often
              referred to as "big bags," "bulk bags," or "tote bags," are large,
              flexible, and collapsible containers designed for the storage and
              transportation of bulk materials, powders, granules, and other
              products. They are commonly used in various industries, including
              agriculture, construction, chemicals, food, and pharmaceuticals.
              Here are the key characteristics of FIBC bulk bags:
            </div>
            <br />
            <div>
              <b>Characteristics:</b>
            </div>
            <ol>
              <li>
                <b>Capacity:</b> FIBC bulk bags come in a wide range of sizes
                and capacities, typically ranging from 500 kg (1,100 lbs.) to
                several tons, making them suitable for handling large quantities
                of materials.
              </li>
              <li>
                <b>Material:</b> These bags are usually made from woven
                polypropylene fabric, which is strong, durable, and resistant to
                moisture and UV radiation. The fabric can be coated or uncoated
                depending on the specific application.
              </li>
              <li>
                <b>Design:</b> FIBC bags have a simple design with four corner
                lifting loops that allow for easy handling using forklifts or
                cranes. Some bags may have additional features like discharge
                spouts or liners for specific applications.
              </li>
              <li>
                <b>Safety:</b> They are designed with safety in mind, and many
                feature safety labels and guidelines for proper handling and
                storage. Static dissipative or conductive options are available
                for materials that are sensitive to static electricity.
              </li>
              <li>
                <b>Customization:</b> FIBC bags can be customized with various
                features, including printing, labeling, and different types of
                closures, to suit specific requirements and branding.
              </li>
              <li>
                <b>Transport Efficiency:</b> These bags are stackable, reducing
                storage and transportation space when empty, and can be
                transported efficiently in containers, trucks, or on pallets.
              </li>
              <li>
                <b>Cost-Effective:</b> FIBC bags offer a cost-effective solution
                for bulk material handling compared to traditional rigid
                containers like drums or pallet boxes.
              </li>
              <li>
                <b>Recyclability:</b> The polypropylene material used in FIBC
                bags is recyclable, contributing to sustainability efforts.
              </li>
              <li>
                <b>Variety:</b> There are different types of FIBC bags tailored
                for specific applications, such as food-grade bags for handling
                food products, conductive bags for flammable materials, and
                sift-proof bags to prevent the leakage of fine powders.
              </li>
            </ol>
            <div>
              FIBC bulk bags are widely used in industries such as agriculture,
              chemicals, construction, and manufacturing for their ability to
              efficiently store and transport large quantities of bulk
              materials. Their durability, handling features, and versatility
              make them a preferred choice for many businesses.
            </div>
          </div>
        </div>
      </>
    );
  }
}
