import { Component } from "react";
import { ISocialMediaProps } from "./ISocialMediaProps";
import { ISocialMediaState } from "./ISocialMediaState";
import styles from "./SocialMedia.module.scss";

export default class SocialMedia extends Component<
  ISocialMediaProps,
  ISocialMediaState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpenContainer: false,
    };
  }

  render() {
    return (
      <div className={styles.socialMediaContainer}>
        {this.state.isOpenContainer && (
          <div className={styles.container}>
            <div className={styles.menusBox}>
              <div className="social">
                <div>
                  <a
                    href="https://www.facebook.com/profile.php?id=61551652212009"
                    className="link facebook"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fa fa-facebook-square"></span>
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.instagram.com/sbg_polychem_morbi/"
                    className="link instagram"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fa fa-instagram"></span>
                  </a>
                </div>
                <div>
                  <a
                    href="https://www.linkedin.com/company/sbg-polychem/"
                    className="link linkedin"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fa fa-linkedin-square"></span>
                  </a>
                </div>
                <div>
                  <a
                    href="https://wa.me/918320213816"
                    className="link whatsapp"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <span className="fa fa-whatsapp"></span>
                  </a>
                </div>
              </div>
            </div>
            <div className={styles.icon}></div>
          </div>
        )}
        <img
          onClick={() => {
            this.setState({ isOpenContainer: !this.state.isOpenContainer });
          }}
          src={"/images/socialMedia.gif"}
          alt="Social media platforms"
        />
      </div>
    );
  }
}
