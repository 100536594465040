import styles from "./Home.module.scss";
import { Component } from "react";
import { IHomeProps } from "./IHomeProps";
import { IHomeState } from "./IHomeState";
import Slider from "react-animated-slider";
import ContactUsFooter from "../ContactUs/ContactUsFooter/ContactUsFooter";
import {
  IN,
  US,
  UA,
  GB,
  UY,
  MZ,
  GH,
  TG,
  DJ,
  PL,
  IQ,
  BG,
  IT,
  NG,
  GR,
  NL,
  CD,
  UG,
  ZA,
  GN,
  TZ,
  KE,
  MG,
  ES,
  ET,
  ZW,
  OM,
  AE,
  BH,
  DE,
  RO,
} from "country-flag-icons/react/3x2";

export default class Home extends Component<IHomeProps, IHomeState> {
  constructor(props: any) {
    super(props);
    this.state = {
      carousalContent: [
        {
          title: "",
          description: "",
          // button: "Buy now",
          image: "/images/Slider2.jpg",
          user: "SBG Polychem",
          userProfile: "/images/Logo.png",
        },
        {
          title: "",
          description: "",
          // button: "Buy now",
          image: "/images/Loom.jpg",
          user: "SBG Polychem",
          userProfile: "/images/Logo.png",
        },
        {
          title: "",
          description: "",
          // button: "Buy now",
          image: "/images/LohiaLSL6.jpg",
          user: "SBG Polychem",
          userProfile: "/images/Logo.png",
        },
      ],
      galleryImage: [
        {
          imageURL: "/images/gallery1.jpg",
        },
        {
          imageURL: "/images/gallery2.jpg",
        },
        {
          imageURL: "/images/gallery3.jpg",
        },
        {
          imageURL: "/images/gallery4.jpg",
        },
        {
          imageURL: "/images/gallery5.jpg",
        },
        {
          imageURL: "/images/gallery6.jpg",
        },
        {
          imageURL: "/images/gallery7.jpg",
        },
        {
          imageURL: "/images/gallery8.jpg",
        },
        {
          imageURL: "/images/gallery10.jpg",
        },
      ],
      Industries: [
        {
          Name: "Grains & Pulses",
          IconPath: "/images/Grains&Pulses.jpg",
        },
        {
          Name: "Fertilizers Chemicals",
          IconPath: "/images/FertilizersChemicals.jpg",
        },
        {
          Name: "Animal & Pet food",
          IconPath: "/images/Animal&Petfood.png",
        },
        {
          Name: "Bird Food",
          IconPath: "/images/BirdFood.jpg",
        },
        {
          Name: "Seeds",
          IconPath: "/images/Seeds.jpg",
        },
        {
          Name: "Flour",
          IconPath: "/images/Flour.png",
        },
        {
          Name: "Spices",
          IconPath: "/images/Spices.jpg",
        },
        {
          Name: "Cement & Wall Plasters",
          IconPath: "/images/Cement&WallPlasters.jpg",
        },
        {
          Name: "Powder & Granules",
          IconPath: "/images/Powder&Granules.jpg",
        },
        {
          Name: "Charcoal",
          IconPath: "/images/Charcoal.jpg",
        },
        {
          Name: "Detergent Powder",
          IconPath: "/images/DetergentPowder.jpg",
        },
        {
          Name: "Milk Powder",
          IconPath: "/images/MilkPowder.jpg",
        },
        {
          Name: "Tea b Coffee",
          IconPath: "/images/TeabCoffee.png",
        },
        {
          Name: "Nuts & Fruits",
          IconPath: "/images/Nuts&Fruits.jpg",
        },
        {
          Name: "Outer & Promotional",
          IconPath: "/images/Outer&Promotional.jpg",
        },
      ],
      country: [
        {
          Name: "India",
          Icon: <IN title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "United States of America",
          Icon: <US title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "United Kingdom",
          Icon: <GB title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Uruguay",
          Icon: <UY title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Mozambique",
          Icon: <MZ title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Ghana",
          Icon: <GH title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Togo",
          Icon: <TG title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Djibouti",
          Icon: <DJ title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Poland",
          Icon: <PL title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Iraq",
          Icon: <IQ title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Bulgaria",
          Icon: <BG title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Italy",
          Icon: <IT title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Nigeria",
          Icon: <NG title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Greece",
          Icon: <GR title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Netherland",
          Icon: <NL title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Congo",
          Icon: <CD title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Uganda",
          Icon: <UG title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "South Africa",
          Icon: <ZA title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Guinea",
          Icon: <GN title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Tanzania",
          Icon: <TZ title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Kenya",
          Icon: <KE title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Madagaskar",
          Icon: <MG title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Spain",
          Icon: <ES title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Ethiopia",
          Icon: <ET title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Zimbabwe",
          Icon: <ZW title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Oman",
          Icon: <OM title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "UAE",
          Icon: <AE title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Bahrain",
          Icon: <BH title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Germany",
          Icon: <DE title="United States" className={styles.countryIcon} />,
        },
        {
          Name: "Romania",
          Icon: <RO title="United States" className={styles.countryIcon} />,
        },
      ],
    };
  }

  render() {
    return (
      <>
        <div className={["row", styles.container].join(" ")}>
          <div className="col-md-12">
            <Slider autoplay={1000} classNames={styles}>
              {this.state.carousalContent.map((item, index) => (
                <div
                  key={index}
                  className={styles.sliderContent}
                  style={{
                    background: `url('${item.image}') no-repeat center center`,
                  }}
                >
                  <div className={styles.inner}>
                    <h1>{item.title}</h1>
                    {item.description && <p>{item.description}</p>}
                    {item.button && (
                      <button
                        onClick={() => {
                          window.location.href = "/aboutus";
                        }}
                      >
                        {item.button}
                      </button>
                    )}
                  </div>
                  <section>
                    <img src={item.userProfile} alt={item.user} />
                    <span>
                      Posted by <strong>{item.user}</strong>
                    </span>
                  </section>
                </div>
              ))}
            </Slider>
          </div>
          <div className={["col-md-12", styles.contentContainer].join(" ")}>
            <div className={styles.block}>
              <h1 className={styles.header}>What SBG do</h1>
              <div className={styles.content}>
                <div>
                  We specialize in manufacturing and exporting PP/HDPE woven
                  packaging materials. Our worldwide delivery services are
                  tailored to meet each customer's unique packaging requirements
                  and delivery schedules. Our company is dedicated to providing
                  the industry with the highest quality products, incorporating
                  the latest innovations in the market while ensuring
                  competitive pricing.
                </div>
                <br />
                <div>
                  Our commitment to product quality and our customer-centric
                  approach have been instrumental in our remarkable growth. With
                  the ongoing support and collaboration of our dedicated team,
                  our valued existing clients, and the prospective clients we
                  aim to connect with in the future, we are determined to
                  continue scaling new heights in our industry.
                </div>
              </div>
            </div>

            <div className={styles.block}>
              <h1 className={styles.header}>Gallery</h1>
              <div className={styles.content}>
                <div className="row">
                  {this.state.galleryImage.map((image) => {
                    return (
                      <div className="col-md-4">
                        <img
                          className={styles.galleryImg}
                          src={image.imageURL}
                          alt="gallery"
                        ></img>
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>

            <div className={["row", styles.rowContainer].join(" ")}>
              <div className="col-md-12">
                <div className={styles.block}>
                  <h1 className={styles.header}>Industries We Serve</h1>
                  <div className={styles.content}>
                    <div
                      className={["row", styles.industryContainer].join(" ")}
                    >
                      {this.state.Industries.map((industry) => {
                        return (
                          <div
                            className={[
                              "col-md-3 col-6",
                              styles.container,
                            ].join(" ")}
                          >
                            <div>
                              <img
                                className={styles.industryIcon}
                                src={industry.IconPath}
                              />
                            </div>
                            <div>{industry.Name}</div>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={["row", styles.rowContainer].join(" ")}>
              <div className="col-md-12">
                <div className={styles.block}>
                  <h1 className={styles.header}>Global FootPrints</h1>
                  <div className={styles.content}>
                    <div className={["row", styles.globalFootPrints].join(" ")}>
                      {this.state.country.map((country) => {
                        return (
                          <div className="col-md-4">
                            {country.Icon}
                            {country.Name}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles.block}>
              <h1 className={styles.header}>Explore SBG</h1>
              <div className={styles.content}>
                <div className="row">
                  <div className="col-md-6">
                    <div
                      onClick={() => {
                        window.location.href = "/";
                      }}
                      className={styles.explorContainer}
                    >
                      <div className={styles.logoSection}>
                        <img
                          className={styles.exploreLogo}
                          src={"/images/Logo.png"}
                          alt="logo"
                        ></img>
                      </div>
                      <div className={styles.titleSection}>SBG Polychem</div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div
                      onClick={() => {
                        window.open("https://www.sbgceramic.com/", "_blank");
                      }}
                      className={[styles.explorContainer, styles.ceramic].join(
                        " "
                      )}
                    >
                      <img
                        className={styles.exploreLogo}
                        src={"/images/SBGInternational.png"}
                        alt="logo"
                      ></img>
                      <div className={styles.titleSection}>
                        SBG International
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ContactUsFooter />
        </div>
      </>
    );
  }
}
