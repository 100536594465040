import { IProductPagesProps } from "./IProductPagesProps";
import { IProductPagesState } from "./IProductPagesState";
import styles from "./ProductPages.module.scss";
import { Component } from "react";

export default class MiltifilamentYarn extends Component<
  IProductPagesProps,
  IProductPagesState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={["row", styles.container].join(" ")}>
          <div className={["col-md-12"].join(" ")}>
            <div className={styles.productImageContainer}>
              <img
                className={styles.productImg}
                src={this.props.imagePath}
              ></img>
            </div>
            <div>
              Multifilament yarn is a type of yarn made from multiple continuous
              strands or filaments of synthetic or natural fibers that are
              twisted or bundled together. These filaments are generally finer
              than the individual fibers used to make traditional spun yarn.
              Multifilament yarns offer several characteristics and advantages:
            </div>
            <br />
            <div>
              <b>Characteristics:</b>
            </div>
            <ol>
              <li>
                <b>Smooth Texture:</b> Multifilament yarns have a smooth texture
                due to the fine filaments used in their construction. This
                smoothness imparts a soft and silky feel to fabrics and textiles
                made from these yarns.
              </li>
              <li>
                <b>Strength:</b> Multifilament yarns are known for their high
                tensile strength, making them exceptionally durable. This
                strength is a result of the bundling of multiple filaments
                together.
              </li>
              <li>
                <b>Lack of Hairiness:</b> Unlike spun yarns, multifilament yarns
                have a minimal tendency to develop loose fibers or "hairs." This
                characteristic contributes to a cleaner and more polished
                appearance in finished products.
              </li>
              <li>
                <b>Versatility:</b> Multifilament yarns are versatile and can be
                made from various materials, including polyester, nylon,
                polypropylene, silk, or other synthetic or natural fibers. This
                versatility allows them to be used in a wide range of
                applications.
              </li>
              <li>
                <b>Smooth Dyeing:</b> Due to their smooth texture, multifilament
                yarns often have excellent dye absorption, resulting in vibrant
                and consistent colors when dyed or printed.
              </li>
              <li>
                <b>Lightweight:</b> These yarns are lightweight, which can be
                advantageous for products that require reduced weight while
                maintaining strength, such as sportswear and technical textiles.
              </li>
              <li>
                <b>Crease Resistance:</b> Fabrics made from multifilament yarns
                tend to be naturally crease-resistant, making them suitable for
                applications where a wrinkle-free appearance is desired.
              </li>
              <li>
                <b>Applications:</b> Multifilament yarns find use in various
                applications, including textiles (clothing, linens, and
                upholstery), technical textiles (automotive, aerospace, and
                filtration), and industrial applications (ropes, cords, and
                nets).
              </li>
              <li>
                <b>Cost-Effective:</b> They are often cost-effective due to
                their durability and efficient use of raw materials.
              </li>
            </ol>
            <div>
              Multifilament yarns are prized for their strength, durability, and
              versatility, making them a preferred choice for a wide range of
              textile and industrial applications. Their smooth texture, lack of
              hairiness, and ability to take on vibrant colors make them
              especially suitable for producing high-quality fabrics and
              products.
            </div>
          </div>
        </div>
      </>
    );
  }
}
