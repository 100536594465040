import { IProductPagesProps } from "./IProductPagesProps";
import { IProductPagesState } from "./IProductPagesState";
import styles from "./ProductPages.module.scss";
import { Component } from "react";

export default class SILTFences extends Component<
  IProductPagesProps,
  IProductPagesState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={["row", styles.container].join(" ")}>
          <div className={["col-md-12"].join(" ")}>
            <div className={styles.productImageContainer}>
              <img
                className={styles.productImg}
                src={this.props.imagePath}
              ></img>
            </div>
            <div>
              Silt fences are erosion control devices used in construction and
              landscaping projects to prevent soil and sediment runoff from
              contaminating water bodies, such as rivers, lakes, and streams.
              These barriers are typically made of geotextile fabric or
              synthetic materials and are installed along the perimeter of a
              construction site or in areas where soil disturbance occurs. Here
              are some key details and characteristics of silt fences:
            </div>
            <br />
            <div>
              <b>Characteristics:</b>
            </div>
            <ol>
              <li>
                <b>Geotextile Fabric:</b> Silt fences are typically made of
                permeable geotextile fabric that allows water to pass through
                while trapping sediment and particles. The fabric is designed to
                filter out sediment and prevent it from entering nearby
                waterways.
              </li>
              <li>
                <b>Erosion Control:</b> The primary purpose of silt fences is to
                control erosion by capturing sediment and preventing it from
                being washed away by rainwater or runoff. This helps maintain
                the integrity of nearby ecosystems and water quality.
              </li>
              <li>
                <b>Installation:</b> Silt fences are installed by burying a
                portion of the fabric in a trench and securing it with stakes or
                posts. The fence is typically sloped slightly downhill to guide
                runoff toward the fence.
              </li>
              <li>
                <b>Permeability:</b> Silt fences are designed to strike a
                balance between allowing water to flow through and trapping
                sediment. They are effective at capturing fine sediment
                particles but still allow water to pass, preventing flooding.
              </li>
              <li>
                <b>Regular Maintenance:</b> To remain effective, silt fences
                require regular inspection and maintenance. Accumulated sediment
                must be removed from the fence, and any damage or breaches must
                be repaired promptly.
              </li>
              <li>
                <b>Environmental Compliance:</b> Silt fences are often a
                regulatory requirement on construction sites to ensure
                compliance with environmental protection laws. They help prevent
                soil erosion and the pollution of natural water bodies.
              </li>
              <li>
                <b>Cost-Effective:</b> Silt fences are a cost-effective erosion
                control measure compared to more complex systems like sediment
                basins or silt ponds.
              </li>
              <li>
                <b>Temporary Barrier:</b> Silt fences are typically considered
                temporary erosion control measures. They are installed during
                construction and landscaping activities and are removed once the
                site is stabilized or when permanent erosion control measures
                are in place.
              </li>
            </ol>
            <div>
              Silt fences are an important tool for protecting water quality and
              preventing soil erosion on construction sites and in areas with
              disturbed soil. Properly installed and maintained silt fences help
              reduce the environmental impact of construction activities and
              promote responsible land management.
            </div>
          </div>
        </div>
      </>
    );
  }
}
