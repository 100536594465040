import { IProductPagesProps } from "./IProductPagesProps";
import { IProductPagesState } from "./IProductPagesState";
import styles from "./ProductPages.module.scss";
import { Component } from "react";

export default class BOPPBags extends Component<
  IProductPagesProps,
  IProductPagesState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={["row", styles.container].join(" ")}>
          <div className={["col-md-12"].join(" ")}>
            <div className={styles.productImageContainer}>
              <img
                className={styles.productImg}
                src={this.props.imagePath}
              ></img>
            </div>
            <div>
              BOPP (Biaxially Oriented Polypropylene) bags are a type of
              packaging material made from a specialized form of polypropylene
              film. These bags are widely used for various packaging
              applications and are known for their clarity, strength, and
              versatility.
            </div>
            <br />
            <div>
              <b>Characteristics:</b>
            </div>
            <ol>
              <li>
                <b>Clarity:</b> BOPP bags have excellent clarity and
                transparency, which allows for easy visibility of the contents.
                This makes them ideal for products that benefit from visual
                presentation, such as snacks, confectionery, and apparel.
              </li>
              <li>
                <b>High Gloss:</b> They often have a glossy appearance that
                enhances the visual appeal of packaged items, making them more
                attractive to consumers.
              </li>
              <li>
                <b>Strength:</b> BOPP bags are strong and have good tensile
                strength, which makes them suitable for packaging a wide range
                of products, including heavy or sharp-edged items.
              </li>
              <li>
                <b>Moisture Resistance:</b> BOPP is inherently
                moisture-resistant, protecting the contents from humidity and
                moisture damage.
              </li>
              <li>
                <b>Barrier Properties:</b> Some BOPP bags are equipped with
                additional barrier layers to provide protection against UV rays,
                gases, and odors, depending on the specific requirements of the
                packaged items.
              </li>
              <li>
                <b>Custom Printing:</b> BOPP bags can be easily customized with
                high-quality printing, allowing for branding, product
                information, and attractive graphics to be displayed directly on
                the packaging.
              </li>
              <li>
                <b>Seal ability:</b> They can be sealed with various methods,
                including heat sealing, adhesive tapes, or resealable zippers,
                depending on the desired level of reseal ability and
                tamper-evident features.
              </li>
              <li>
                <b>Environmentally Friendly:</b> BOPP is a recyclable material,
                which makes these bags relatively eco-friendly compared to some
                other packaging options.
              </li>
              <li>
                <b>Versatility:</b> BOPP bags are used for packaging a wide
                variety of products, including snacks, candies, textiles,
                stationery, and promotional items.
              </li>
              <li>
                <b>Cost-Effective:</b> They offer a cost-effective packaging
                solution, especially for products where visual appeal and
                clarity are important.
              </li>
            </ol>
            <div>
              BOPP bags are widely used for a range of products, including
              snacks, dry goods, pet food, and promotional items, due to their
              transparency, strength, and customization options. They offer an
              excellent combination of product protection and visual appeal.
            </div>
          </div>
        </div>
      </>
    );
  }
}
