import styles from "./Footer.module.scss";
import { Component } from "react";
import { IFooterProps } from "./IFooterProps";
import { IFooterState } from "./IFooterState";

export default class Footer extends Component<IFooterProps, IFooterState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={["row", styles.container].join(" ")}>
        <div className="col-md-12">
          @2023 SBG Polychem, All rights reserved.
        </div>
      </div>
    );
  }
}
