import styles from "./ContactUs.module.scss";
import { Component } from "react";
import { Store } from "react-notifications-component";
import { IContactUsProps } from "./IContactUsProps";
import { IContactUsState } from "./IContactUsState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationPin,
  faEnvelope,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

export default class ContactUs extends Component<
  IContactUsProps,
  IContactUsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      name: "",
      email: "",
      mobileNo: "",
      message: "",
      isValid: false,
      contactUsEmailId: "sbgpolychem@gmail.com",
    };

    this.submitInquiryOnClick = this.submitInquiryOnClick.bind(this);
    this.updateIsValid = this.updateIsValid.bind(this);
  }

  submitInquiryOnClick(): void {
    let sbgPolychemEmail = this.state.contactUsEmailId;
    let emailSubject = `Enquiry from ${this.state.name}`;
    let emailBody = `Hi Team,<br/><br/>${this.state.message}<br/><br/>Email Address: ${this.state.email}<br/>Mobile No: ${this.state.mobileNo}<br/><br/>Thanks,<br/>${this.state.name}`;

    window.location.href = `mailto:${sbgPolychemEmail}?subject=${emailSubject}&body=${emailBody.replace(
      /<br\s*\/?>/gm,
      "%0D%0A"
    )}`;
    // Store.addNotification({
    //   title: "Inquiry",
    //   message: "Inquiry Submitted Successfully.",
    //   type: "success", // 'default', 'success', 'info', 'warning', 'danger'
    //   container: "top-right", // where to position the notifications
    //   animationIn: ["animated", "fadeIn"], // animate.css classes that's applied
    //   animationOut: ["animated", "fadeOut"], // animate.css classes that's applied
    //   dismiss: {
    //     duration: 3000,
    //   },
    // });
    this.setState({
      name: "",
      email: "",
      mobileNo: "",
      message: "",
      isValid: false,
    });
  }

  updateIsValid(): void {
    if (
      this.state.name &&
      this.state.email &&
      this.state.mobileNo &&
      this.state.message
    ) {
      this.setState({
        isValid: true,
      });
    } else {
      this.setState({
        isValid: false,
      });
    }
  }

  render() {
    return (
      <div className={["row", styles.container].join(" ")}>
        <div className={["col-md-12", styles.map].join(" ")}>
          <div className={styles.mapLocation}>
            <iframe
              className={styles.mapLocation}
              title="SBG Polychem"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3678.04770450571!2d70.81239227498361!3d22.80069617932999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3959f3eeb657ab51%3A0x6e7c30c2833c6a78!2sSBG%20POLYCHEM!5e0!3m2!1sen!2sin!4v1690034701998!5m2!1sen!2sin"
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div className={["col-md-12", styles.detailsContainer].join(" ")}>
          <div className={styles.details}>
            <div className="row">
              <div
                className={["col-md-6", styles.contactDetailsContainer].join(
                  " "
                )}
              >
                <h1 className={styles.contactDetailsHeader}>CONTACT DETAILS</h1>
                <div className={styles.header}>
                  Please contact us to know more about us using below details:
                </div>
                <div>
                  <FontAwesomeIcon
                    className={styles.icon}
                    icon={faLocationPin}
                  />
                  <div className={styles.header}>Address:</div>
                  <div className={styles.detailTxt}>
                    405, Star Arcade, Sanala Rd, opp. Sky Mall, Patel Colony,
                    Vaibhav Nagar Society, Sanala, Morbi, Gujarat 363641
                  </div>
                </div>

                <div>
                  <FontAwesomeIcon className={styles.icon} icon={faEnvelope} />
                  <div className={styles.header}>Email Address: </div>
                  <div>
                    <span className={styles.emailHeader}>For Domestic - </span>
                    <span className={styles.detailTxt}>
                      <a href={`mailto:${this.state.contactUsEmailId}`}>
                        {this.state.contactUsEmailId}
                      </a>
                    </span>
                  </div>
                  <div>
                    <span className={styles.emailHeader}>For Export - </span>
                    <span className={styles.detailTxt}>
                      <a href={`mailto:export@sbgpolychem.com`}>
                        export@sbgpolychem.com
                      </a>
                    </span>
                  </div>
                </div>

                <div>
                  <FontAwesomeIcon className={styles.icon} icon={faPhone} />
                  <div className={styles.header}>Contact Nubmer:</div>
                  <div className={styles.detailTxt}>+91 83202 13816</div>
                </div>
              </div>
              <div className={["col-md-6", styles.inquiryContainer].join(" ")}>
                <h1 className={styles.inquiryHeader}>INQUIRY</h1>
                <div className="row">
                  <div className="col-md-12">
                    <div className="floating-label">
                      <input
                        value={this.state.name}
                        className="floating-input"
                        type="text"
                        placeholder=" "
                        onChange={(e) => {
                          this.setState({ name: e.target.value }, () => {
                            this.updateIsValid();
                          });
                        }}
                      />
                      <span className="highlight"></span>
                      <label>
                        Name <span className="required">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="floating-label">
                      <input
                        value={this.state.email}
                        className="floating-input"
                        type="text"
                        placeholder=" "
                        onChange={(e) => {
                          this.setState({ email: e.target.value }, () => {
                            this.updateIsValid();
                          });
                        }}
                      />
                      <span className="highlight"></span>
                      <label>
                        Email <span className="required">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="floating-label">
                      <input
                        value={this.state.mobileNo}
                        className="floating-input"
                        type="text"
                        placeholder=" "
                        onChange={(e) => {
                          this.setState({ mobileNo: e.target.value }, () => {
                            this.updateIsValid();
                          });
                        }}
                      />
                      <span className="highlight"></span>
                      <label>
                        Mobile No <span className="required">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="floating-label">
                      <textarea
                        value={this.state.message}
                        className="floating-input"
                        name="Text1"
                        placeholder=" "
                        cols={40}
                        rows={5}
                        onChange={(e) => {
                          this.setState({ message: e.target.value }, () => {
                            this.updateIsValid();
                          });
                        }}
                      ></textarea>
                      <span className="highlight"></span>
                      <label>
                        Message <span className="required">*</span>
                      </label>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <button
                      onClick={this.submitInquiryOnClick}
                      disabled={!this.state.isValid}
                      className={["btn"].join(" ")}
                    >
                      <span>SUBMIT INQUIRY</span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
