import { getTheme } from "@fluentui/react";
import { FontWeights } from "@fluentui/react/lib/Styling";
import { mergeStyleSets } from "@fluentui/merge-styles";
import { memoizeFunction } from "@fluentui/react/lib/Utilities";

type IModalClassNames = {
  container: string;
  cancelButton: string;
  container_small: string;
  container_large: string;
  header: string;
  headerTitle: string;
  body: string;
  containerMaintain: string;
  headerMaintain: string;
};

const theme = getTheme();

export const getModalClassNames = memoizeFunction((): IModalClassNames => {
  return mergeStyleSets({
    container: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      width: "950px",
    },
    cancelButton: {
      color: theme.palette.neutralPrimary,
      marginLeft: "auto",
      marginTop: "4px",
      marginRight: "2px",
      cursor: "pointer",
      selectors: {
        "&:hover": { color: theme.palette.neutralDark },
      },
    },
    header: [
      theme.fonts.xLarge,
      {
        flex: "1 1 auto",
        color: theme.palette.neutralPrimary,
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "12px 12px 14px 24px",
        boxShadow: "2px 2px 10px #b0843f",
        marginBottom: "15px",
      },
    ],
    headerTitle: {
      textTransform: "uppercase",
      color: "#196173",
    },
    body: {
      flex: "4 4 auto",
      padding: "0 24px 24px 24px",
      overflowY: "hidden",
      selectors: {
        p: { margin: "14px 0" },
        "p:first-child": { marginTop: 0 },
        "p:last-child": { marginBottom: 0 },
      },
    },
    container_small: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      width: "700px",
    },
    container_large: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
      width: "1084px",
    },
    containerMaintain: {
      display: "flex",
      flexFlow: "column nowrap",
      alignItems: "stretch",
    },
    headerMaintain: [
      theme.fonts.xLarge,
      {
        flex: "1 1 auto",
        color: "#002776",
        display: "flex",
        alignItems: "center",
        fontWeight: FontWeights.semibold,
        padding: "25px 12px 24px 45px",
        fontSize: 25,
      },
    ],
  });
});
