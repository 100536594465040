import styles from "./AboutUs.module.scss";
import { Component } from "react";
import { IAboutUsProps } from "./IAboutUsProps";
import { IAboutUsState } from "./IAboutUsState";
import ContactUsFooter from "../ContactUs/ContactUsFooter/ContactUsFooter";

export default class AboutUs extends Component<IAboutUsProps, IAboutUsState> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className={["row", styles.container].join(" ")}>
        <div className={["col-md-12", styles.headerImg].join(" ")}></div>
        <div className="col-md-12">
          <div className={["row", styles.rowContainer].join(" ")}>
            <div className="col-md-12">
              <div className={styles.contentContainer}>
                <div className={styles.content}>
                  <div>
                    We specialize in the production and global export of PP/HDPE
                    woven packaging materials. Our commitment is to deliver our
                    products worldwide, with pricing that is customized to meet
                    the specific packaging requirements and delivery preferences
                    of each customer. Our company's primary focus is on
                    providing the industry with the highest quality products and
                    the latest innovations available in the market, all while
                    maintaining the utmost competitiveness in pricing.
                  </div>
                  <br />
                  <div>
                    Our remarkable success can be attributed to both the
                    exceptional quality of our products and our customer-centric
                    approach. It is the combination of these factors that has
                    allowed us to achieve significant growth, and we remain
                    steadfast in our commitment to scaling new heights. With the
                    continued support and collaboration of our dedicated team,
                    our valued existing clients, and the prospective clients we
                    aim to welcome in the future, we are determined to reach
                    even greater heights in our journey ahead.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={["row", styles.rowContainer].join(" ")}>
            <div className="col-md-12">
              <div className={styles.contentContainer}>
                <h1 className={styles.header}>
                  <img
                    className={styles.missionIcon}
                    src={"/images/Vision.png"}
                    alt="vision"
                  />
                  <span>Vision</span>
                </h1>
                <div className={styles.content}>
                  <span>
                    To be the global leader in innovative and sustainable
                    packaging solutions, setting the standard for excellence in
                    quality, service, and environmental responsibility. We
                    aspire to be the preferred choice for customers seeking
                    reliable, versatile, and eco-friendly packaging solutions
                    worldwide.
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className={["row", styles.rowContainer].join(" ")}>
            <div className="col-md-12">
              <div className={styles.contentContainer}>
                <h1 className={styles.header}>
                  <img
                    className={styles.missionIcon}
                    src={"/images/Mission.png"}
                    alt="mission"
                  />
                  <span>Mission</span>
                </h1>
                <div className={styles.content}>
                  <span>
                    At SBG, our mission is to provide our customers with
                    top-quality, customized packaging materials that enhance the
                    value of their products while minimizing their environmental
                    footprint. We are committed to:
                    <ol className={styles.visionBullet}>
                      <li>
                        <b>Quality Excellence:</b> Continuously improving our
                        products and processes to deliver packaging solutions
                        that meet or exceed customer expectations.
                      </li>
                      <li>
                        <b>Innovation:</b> Driving innovation in packaging
                        technology to create products that are efficient,
                        durable, and environmentally responsible.
                      </li>
                      <li>
                        <b>Sustainability:</b> Minimizing the environmental
                        impact of our operations by embracing sustainable
                        practices, reducing waste, and promoting recyclability.
                      </li>
                      <li>
                        <b>Customer Satisfaction:</b> Building lasting
                        partnerships with our customers by understanding their
                        unique needs and consistently delivering exceptional
                        service and support.
                      </li>
                      <li>
                        <b>Employee Empowerment:</b> Fostering a culture of
                        collaboration, growth, and empowerment among our
                        employees, ensuring a motivated and dedicated team.
                      </li>
                      <li>
                        <b>Global Reach:</b> Expanding our global presence to
                        serve customers in diverse markets, providing them with
                        reliable and timely access to our packaging solutions.
                      </li>
                      <li>
                        <b>Community Engagement:</b> Contributing positively to
                        the communities in which we operate by supporting local
                        initiatives and promoting responsible corporate
                        citizenship.
                      </li>
                    </ol>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="col-md-12">
          <img
            className={styles.aboutUsImages}
            src="/images/OurProductionLine.png"
          ></img>
          <img
            className={styles.aboutUsImages}
            src="/images/Machinery.png"
          ></img>
        </div>
        <ContactUsFooter />
      </div>
    );
  }
}
