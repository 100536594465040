import { IProductPagesProps } from "./IProductPagesProps";
import { IProductPagesState } from "./IProductPagesState";
import styles from "./ProductPages.module.scss";
import { Component } from "react";

export default class LenoRaschelBag extends Component<
  IProductPagesProps,
  IProductPagesState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={["row", styles.container].join(" ")}>
          <div className={["col-md-12"].join(" ")}>
            <div className={styles.productImageContainer}>
              <img
                className={styles.productImg}
                src={this.props.imagePath}
              ></img>
            </div>
            <div>
              Leno bags and Raschel bags are specialized types of woven bags
              used for various packaging applications, especially in agriculture
              and construction.
            </div>
            <br />
            <div>
              Leno bags are made using a special weaving technique known as the
              "Leno weave." This weave creates an open mesh-like structure that
              offers various advantages.
            </div>
            <br />
            <div>
              Raschel bags are created using the Raschel knitting technique,
              which produces a tighter, more solid fabric
            </div>
            <br />
            <div>
              They have distinct characteristics that make them suitable for
              specific purposes:
            </div>
            <br />
            <div>
              <b>Characteristics:</b>
            </div>
            <ol>
              <li>
                <b>Mesh Structure:</b> Both Leno and Raschel bags are
                characterized by their mesh or net-like structure. This mesh
                design allows for ventilation and airflow, making them ideal for
                products that require breathability, such as fruits, vegetables,
                or construction materials.
              </li>
              <li>
                <b>Strength and Durability:</b> These bags are known for their
                strength and durability, which is essential for carrying heavy
                or bulky items. The mesh structure doesn't compromise their
                load-bearing capacity.
              </li>
              <li>
                <b>Flexible and Lightweight:</b> Leno and Raschel bags are
                relatively lightweight, making them easy to handle and
                transport, even when empty.
              </li>
              <li>
                <b>Resistant to Tears:</b> Their woven design helps prevent
                tears and punctures, ensuring the safe transportation of goods.
              </li>
              <li>
                <b>UV Resistance:</b> Many Leno and Raschel bags are treated to
                resist UV radiation, making them suitable for outdoor storage
                and transport.
              </li>
              <li>
                <b>Customizable:</b> These bags can be customized with printing
                and labeling for branding and product information.
              </li>
              <li>
                <b>Versatility:</b> Leno bags are often used for packaging
                fruits and vegetables, while Raschel bags find applications in
                the construction industry, especially for containing and
                transporting aggregates like sand and gravel.
              </li>
              <li>
                <b>Cost-Effective:</b> They offer a cost-effective packaging
                solution, especially for products that benefit from ventilation
                and airflow.
              </li>
              <li>
                <b>Recyclable:</b> Leno and Raschel bags are usually made from
                recyclable materials, contributing to environmental
                sustainability.
              </li>
            </ol>
            <div>
              In summary, Leno bags are characterized by their open mesh
              structure, ventilation, and visibility, making them ideal for
              perishable goods, while Raschel bags are known for their high
              density, durability, and tear resistance, making them suitable for
              heavy or bulk items and long-term storage.
            </div>
          </div>
        </div>
      </>
    );
  }
}
