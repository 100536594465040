import { IProductPagesProps } from "./IProductPagesProps";
import { IProductPagesState } from "./IProductPagesState";
import styles from "./ProductPages.module.scss";
import { Component } from "react";

export default class PPWovenFabric extends Component<
  IProductPagesProps,
  IProductPagesState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={["row", styles.container].join(" ")}>
          <div className={["col-md-12"].join(" ")}>
            <div className={styles.productImageContainer}>
              <img
                className={styles.productImg}
                src={this.props.imagePath}
              ></img>
            </div>
            <div>
              PP (Polypropylene) and HDPE (High-Density Polyethylene) woven
              fabric, often referred to as woven polypropylene or woven
              polyethylene fabric, is a versatile material used in a variety of
              applications. Here are some key details about PP/HDPE woven fabric
              and its characteristics:
            </div>
            <br />
            <div>
              <b>Characteristics:</b>
            </div>
            <ol>
              <li>
                <b>Strength and Durability:</b> PP/HDPE woven fabric is known
                for its exceptional strength and durability. It can withstand
                heavy loads and resist tearing, making it suitable for demanding
                applications.
              </li>
              <li>
                <b>Weather Resistance:</b> This fabric is resistant to UV
                radiation and weather conditions, making it ideal for outdoor
                use. It can withstand prolonged exposure to sunlight without
                significant degradation.
              </li>
              <li>
                <b>Water Resistance:</b> While not entirely waterproof, PP/HDPE
                woven fabric exhibits good water resistance. It can protect
                contents from light rain and moisture to some extent.
              </li>
              <li>
                <b>Lightweight:</b> Despite its strength, woven PP/HDPE fabric
                is relatively lightweight, making it easy to handle and
                transport.
              </li>
              <li>
                <b>Breathability:</b> The fabric's woven structure allows for
                air circulation, which is beneficial for products that require
                ventilation or aeration, such as agricultural goods.
              </li>
              <li>
                <b>Customizability:</b> PP/HDPE woven fabric can be easily
                customized with printing and coloring for branding and product
                identification.
              </li>
              <li>
                <b>Recyclability:</b> Like the materials themselves, PP/HDPE
                woven fabric is recyclable, contributing to sustainability
                efforts.
              </li>
            </ol>

            {/* <br /> */}
            {/* <div>
              <b>Applications:</b>
            </div>
            <div>
              PP/HDPE woven fabric is used in various applications, including:
            </div>
            <ol>
              <li>
                <b>Packaging:</b> It is commonly used to make sacks and bags for
                storing and transporting a wide range of goods, including
                grains, cement, fertilizers, and more.
              </li>
              <li>
                <b>Construction:</b> It is employed for scaffolding nets, debris
                containment, and as a protective covering for construction
                materials.
              </li>
              <li>
                <b>Agriculture:</b> PP/HDPE fabric is used for shade nets,
                ground covers, and as protective barriers for crops.
              </li>
              <li>
                <b>Geotextiles:</b> It serves as geotextile fabric for erosion
                control, soil stabilization, and drainage in civil engineering
                projects.
              </li>
              <li>
                <b>Flood Control:</b> It can be used to make sandbags for flood
                control and emergency situations.
              </li>
              <li>
                <b>Outdoor Furniture:</b> PP/HDPE woven fabric is used in the
                production of outdoor furniture due to its weather resistance.
              </li>
            </ol> */}
            <div>
              In summary, PP/HDPE woven fabric is a durable and versatile
              material with characteristics such as strength, weather
              resistance, and breathability. Its wide range of applications
              makes it a valuable choice in various industries.
            </div>
          </div>
        </div>
      </>
    );
  }
}
