import styles from "./Products.module.scss";
import { Component } from "react";
import { IProductsProps } from "./IProductsProps";
import { IProductsState } from "./IProductsState";
import ProductBox from "./ProductBox/ProductBox";
import ContactUsFooter from "../ContactUs/ContactUsFooter/ContactUsFooter";
import PPWovenFabric from "./ProductPages/PPWovenFabric";
import PPWovenBag from "./ProductPages/PPWovenBag";
import BOPPBags from "./ProductPages/BOPPBags";
import FIBCBags from "./ProductPages/FIBCBags";
import LenoRaschelBag from "./ProductPages/LenoRaschelBag";
import SILTFences from "./ProductPages/SILTFences";
import GarbageBag from "./ProductPages/GarbageBag";
import MiltifilamentYarn from "./ProductPages/MiltifilamentYarn";
import PELiner from "./ProductPages/PELiner";

export default class Products extends Component<
  IProductsProps,
  IProductsState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      products: [
        {
          title: "PP Woven Fabric",
          imagePath: "/images/PPWovenFabric.jpg",
          detailPage: <PPWovenFabric imagePath="/images/PPWovenFabric.jpg" />,
        },
        {
          title: "PP Woven Bag",
          imagePath: "/images/PPWovenBag.png",
          detailPage: <PPWovenBag imagePath="/images/PPWovenBag.png" />,
        },
        {
          title: "BOPP Bag",
          imagePath: "/images/BOPPBag.jpg",
          detailPage: <BOPPBags imagePath="/images/BOPPBag.jpg" />,
        },
        {
          title: "FIBC Bag",
          imagePath: "/images/FIBCBag.jpg",
          detailPage: <FIBCBags imagePath="/images/FIBCBag.jpg" />,
        },
        {
          title: "Leno / Raschel Bag",
          imagePath: "/images/LenoRaschelBag.png",
          detailPage: <LenoRaschelBag imagePath="/images/LenoRaschelBag.png" />,
        },
        {
          title: "SILT Fences",
          imagePath: "/images/SILTFences.jpg",
          detailPage: <SILTFences imagePath="/images/SILTFences.jpg" />,
        },
        {
          title: "PE Liner",
          imagePath: "/images/pe liner.jpg",
          detailPage: <PELiner imagePath="/images/pe liner.jpg" />,
        },
        {
          title: "Garbage Bag",
          imagePath: "/images/GarbageBag.png",
          detailPage: <GarbageBag imagePath="/images/GarbageBag.png" />,
        },
        {
          title: "Multifilament Yarn",
          imagePath: "/images/MultifilamentYarn.jpg",
          detailPage: (
            <MiltifilamentYarn imagePath="/images/MultifilamentYarn.jpg" />
          ),
        },
      ],
    };
  }

  render() {
    return (
      <div className={["row", styles.container].join(" ")}>
        <div className={["col-md-12", styles.headerImg].join(" ")}>
          <h1>Products</h1>
        </div>
        <div className="col-md-12">
          <div className={["row", styles.productDetailContainer].join(" ")}>
            <div className="col-md-12">
              <div className={styles.contentContainer}>
                <h1 className={styles.header}>Why SBG?</h1>
                <div className={styles.content}>
                  <div>
                    As a customer-centric company, SBG consistently takes
                    proactive steps to prioritize delivering numerous advantages
                    to our valued clients. Throughout every stage of our
                    processes and in all scenarios, we are dedicated to making
                    decisions that ultimately benefit our clients. By adopting a
                    client-centric perspective, we ensure that our clients
                    always experience the advantages of a favorable Return on
                    Investment.
                  </div>
                  <br />
                  <div>
                    Our extensive experience in this industry offers a host of
                    benefits, including meticulous planning, tailored design,
                    product customization, transparent product status updates,
                    punctual deliveries, and ongoing post-sales communication
                    with our customers.{" "}
                  </div>
                  <br />
                  <div>
                    Staying abreast of the latest advancements and technologies
                    in the materials and machinery industries enables us to keep
                    pace with the evolving landscape and consistently provide
                    cutting-edge products to our clients at competitive prices.
                    This commitment positions us as the preferred choice for
                    fulfilling a wide range of packaging needs.
                  </div>
                </div>
              </div>
            </div>
            {this.state.products.map((productdetail) => {
              return <ProductBox productDetails={productdetail} />;
            })}
          </div>
        </div>
        <ContactUsFooter />
      </div>
    );
  }
}
