import SBGModal from "../../Common/SBGModal/SBGModal";
import { getModalClassNames } from "../../Common/SBGModal/SBGModal.classNames";
import { IProductBoxProps } from "./IProductBoxProps";
import { IProductBoxState } from "./IProductBoxState";
import styles from "./ProductBox.module.scss";
import { Component } from "react";

export default class ProductBox extends Component<
  IProductBoxProps,
  IProductBoxState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      isOpenDetails: false,
    };

    this.onCloseModals = this.onCloseModals.bind(this);
    this.getProductDetailChildComponent =
      this.getProductDetailChildComponent.bind(this);
    this.onProductClick = this.onProductClick.bind(this);
  }

  onCloseModals(): void {
    this.setState({
      isOpenDetails: false,
    });
  }

  getProductDetailChildComponent(): JSX.Element {
    return (
      <div className="ror">
        <div className="col-md-12">{this.props.productDetails.detailPage}</div>
      </div>
    );
  }

  onProductClick(): void {
    this.setState({
      isOpenDetails: true,
    });
  }

  render() {
    return (
      <>
        {this.state.isOpenDetails && (
          <>
            <SBGModal
              titleId="new-id"
              isOpen={this.state.isOpenDetails}
              isBlocking={true}
              onDismiss={this.onCloseModals}
              containerClassName={getModalClassNames().container_large}
              childComponent={this.getProductDetailChildComponent()}
              modalHeader={this.props.productDetails.title}
            />
          </>
        )}
        <div
          className={["col-md-4 col-12", styles.productBoxContainer].join(" ")}
        >
          <div onClick={this.onProductClick} className={styles.innerContainer}>
            <div className={styles.titleBox}>
              <img src={this.props.productDetails.imagePath} alt="productImg" />
              <span>{this.props.productDetails.title}</span>
            </div>
          </div>
        </div>
      </>
    );
  }
}
