import styles from "./Header.module.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { Component } from "react";
import { NavLink } from "reactstrap";
import { Link } from "react-router-dom";
import { IHeaderProps } from "./IHeaderProps";
import { IHeaderState } from "./IHeaderState";

export default class Header extends Component<IHeaderProps, IHeaderState> {
  linksArray = [
    {
      path: "/",
      title: "Home",
    },
    {
      path: "/aboutus",
      title: "About Us",
    },
    {
      path: "/products",
      title: "Products",
    },
    {
      path: "/contactus",
      title: "Contact Us",
    },
  ];

  constructor(props: any) {
    super(props);
    this.state = {
      activePathName: window.location.pathname,
      isOpenTogglerMenu: false,
    };
  }
  render() {
    return (
      <div className={["row", styles.container].join(" ")}>
        <div className="col-md-12">
          <button
            onClick={() => {
              this.setState({
                isOpenTogglerMenu: !this.state.isOpenTogglerMenu,
              });
            }}
            className={["navbar-toggler", styles.eclipseMenu].join(" ")}
            type="button"
          >
            <FontAwesomeIcon className={styles.icon} icon={faBars} />
          </button>
          {this.state.isOpenTogglerMenu && (
            <div className={styles.eclipseMenuContainer}>
              <div className={styles.menuBoxSeperater}></div>
              <div className={styles.menuBox}>
                {this.linksArray.map((link, index) => {
                  return (
                    <div
                      key={`menuItem${link.path}`}
                      className={[
                        this.state.activePathName === link.path
                          ? styles.selected
                          : "",
                      ].join(" ")}
                    >
                      <NavLink
                        tag={Link}
                        to={link.path}
                        key={link.path}
                        onClick={(e) => {
                          this.setState({
                            activePathName: link.path,
                            isOpenTogglerMenu: false,
                          });
                        }}
                      >
                        {link.title}
                      </NavLink>
                    </div>
                  );
                })}
              </div>
            </div>
          )}
          <div
            onClick={() => {
              window.location.href = "/";
            }}
            className={styles.appLogo}
          >
            <img src={"/images/Logo.png"} alt="logo" />
          </div>
          <div key={"headerRow"} className={[styles.menuRow].join(" ")}>
            {this.linksArray.map((link, index) => {
              return (
                <div
                  key={`menuItem${link.path}`}
                  className={[
                    styles.menuItem,
                    this.state.activePathName === link.path
                      ? styles.selected
                      : "",
                  ].join(" ")}
                >
                  <NavLink
                    tag={Link}
                    to={link.path}
                    key={link.path}
                    onClick={(e) => {
                      this.setState({ activePathName: link.path });
                    }}
                  >
                    {link.title}
                  </NavLink>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}
