import "react-notifications-component/dist/theme.css";
import Layout from "./Components/Layout/Layout";
import { Route, Routes } from "react-router-dom";
import AppRoutes from "./AppRoutes";
import { Component } from "react";
import { IAppProps } from "./IAppProps";
import { IAppState } from "./IAppState";
import React from "react";
import "react-animated-slider/build/horizontal.css";

export default class App extends Component<IAppProps, IAppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      isLoading: true,
    };

    this.stopLoading = this.stopLoading.bind(this);
  }

  componentDidMount() {
    setTimeout(this.stopLoading, 1000);
  }

  private stopLoading() {
    this.setState({
      isLoading: false,
    });
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div className="row">
          <div className="col-md-12 loaderRow">
            <img className="loaderImage" src="/images/loader2.gif"></img>
          </div>
        </div>
      );
    }

    return (
      <Layout>
        <Routes>
          {AppRoutes.map((route, index) => {
            const { element, ...rest } = route;

            const ClonedElementWithMoreProps = React.cloneElement(element, {
              // test: "test123",
            });

            return (
              <Route
                key={index}
                {...rest}
                element={ClonedElementWithMoreProps}
              />
            );
          })}
        </Routes>
      </Layout>
    );
  }
}
