import { IProductPagesProps } from "./IProductPagesProps";
import { IProductPagesState } from "./IProductPagesState";
import styles from "./ProductPages.module.scss";
import { Component } from "react";

export default class PELiner extends Component<
  IProductPagesProps,
  IProductPagesState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={["row", styles.container].join(" ")}>
          <div className={["col-md-12"].join(" ")}>
            <div className={styles.productImageContainer}>
              <img
                className={styles.productImg}
                src={this.props.imagePath}
              ></img>
            </div>
            <div>
              PE (Polyethylene) liners are thin sheets or films made from
              polyethylene, a widely used and versatile plastic material. These
              liners are used in various industries and applications for their
              protective and containment properties. Here are some key details
              and characteristics of PE liners:
            </div>
            <br />
            <div>
              <b>Characteristics:</b>
            </div>
            <ol>
              <li>
                <b>Material:</b> PE liners are made from polyethylene, which is
                known for its flexibility, chemical resistance, and durability.
              </li>
              <li>
                <b>Thickness:</b> PE liners come in various thicknesses, ranging
                from very thin films to thicker sheets, depending on the
                intended use and the level of protection required.
              </li>
              <li>
                <b>Chemical Resistance:</b> Polyethylene liners exhibit
                excellent resistance to a wide range of chemicals, making them
                suitable for containing and protecting against corrosive
                substances.
              </li>
              <li>
                <b>Waterproof:</b> PE liners are inherently waterproof, making
                them ideal for applications where moisture protection is
                essential.
              </li>
              <li>
                <b>Flexibility:</b> They are highly flexible and can conform to
                different shapes and contours, making them adaptable for lining
                irregularly shaped containers or surfaces.
              </li>
              <li>
                <b>Tear Resistance:</b> PE liners are generally tear-resistant,
                ensuring that they can withstand handling and potential
                punctures or abrasions.
              </li>
              <li>
                <b>UV Resistance:</b> Some PE liners are UV-stabilized, which
                means they can resist damage from prolonged exposure to sunlight
                and outdoor conditions.
              </li>
              <li>
                <b>Temperature Resistance:</b> Polyethylene liners can handle a
                wide temperature range, making them suitable for both hot and
                cold environments.
              </li>
              <li>
                <b>Hygienic:</b> They are often used in food processing,
                pharmaceutical, and medical applications because they are easy
                to clean and maintain high levels of hygiene.
              </li>
              <li>
                <b>Customization:</b> PE liners can be customized in terms of
                size, thickness, and shape to fit specific containers or
                equipment.
              </li>
            </ol>
            <div>
              PE liners offer valuable protection and containment properties in
              a wide range of industries and applications, contributing to the
              safe storage, transportation, and handling of various materials
              and substances.
            </div>
          </div>
        </div>
      </>
    );
  }
}
