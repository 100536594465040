import styles from "./ContactUsFooter.module.scss";
import { Component } from "react";
import { IContactUsFooterProps } from "./IContactUsFooterProps";
import { IContactUsFooterState } from "./IContactUsFooterState";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLocationPin,
  faEnvelope,
  faPhone,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons";

export default class ContactUsFooter extends Component<
  IContactUsFooterProps,
  IContactUsFooterState
> {
  constructor(props: any) {
    super(props);
    this.state = {
      contactUsEmailId: "sbgpolychem@gmail.com",
    };
  }

  render() {
    return (
      <div className={["row", styles.container].join(" ")}>
        <div className="col-md-6">
          <div className={styles.header}>
            <FontAwesomeIcon className={styles.icon} icon={faLocationPin} />
            Address:
          </div>
          <div className={styles.detailTxt}>
            405, Star Arcade, Sanala Rd, opp. Sky Mall, Patel Colony, Vaibhav
            Nagar Society, Sanala, Morbi, Gujarat 363641
          </div>
          <div className={styles.googleMapLink}>
            <a
              target="_blank"
              href="https://www.google.com/maps/place/SBG+POLYCHEM/@22.8007011,70.8100963,17z/data=!3m1!4b1!4m6!3m5!1s0x3959f3eeb657ab51:0x6e7c30c2833c6a78!8m2!3d22.8006962!4d70.8149672!16s%2Fg%2F11szd0zrl7?entry=ttu"
            >
              <FontAwesomeIcon className={styles.icon} icon={faLocationArrow} />
              Google Map Location
            </a>
          </div>
        </div>
        <div className="col-md-6">
          <div>
            <div className={styles.header}>
              <FontAwesomeIcon className={styles.icon} icon={faEnvelope} />
              Email Address:{" "}
            </div>
            <div>
              <span className={styles.emailHeader}>For Domestic - </span>
              <span className={styles.detailTxt}>
                <a href={`mailto:${this.state.contactUsEmailId}`}>
                  {this.state.contactUsEmailId}
                </a>
              </span>
            </div>
            <div>
              <span className={styles.emailHeader}>For Export - </span>
              <span className={styles.detailTxt}>
                <a href={`mailto:export@sbgpolychem.com`}>
                  export@sbgpolychem.com
                </a>
              </span>
            </div>
          </div>
          <div>
            <div className={styles.header}>
              <FontAwesomeIcon className={styles.icon} icon={faPhone} />
              Contact Nubmer:
            </div>
            <div className={styles.detailTxt}>+91 83202 13816</div>
          </div>
        </div>
      </div>
    );
  }
}
