import AboutUs from "./Components/AboutUs/AboutUs";
import ContactUs from "./Components/ContactUs/ContactUs";
import Home from "./Components/Home/Home";
import Products from "./Components/Products/Products";

const AppRoutes = [
  {
    index: true,
    element: <Home></Home>,
  },
  {
    path: "/products",
    element: <Products></Products>,
  },
  {
    path: "/aboutus",
    element: <AboutUs></AboutUs>,
  },
  {
    path: "/contactus",
    element: <ContactUs></ContactUs>,
  },
];

export default AppRoutes;
