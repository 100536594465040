import { IProductPagesProps } from "./IProductPagesProps";
import { IProductPagesState } from "./IProductPagesState";
import styles from "./ProductPages.module.scss";
import { Component } from "react";

export default class PPWovenBag extends Component<
  IProductPagesProps,
  IProductPagesState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={["row", styles.container].join(" ")}>
          <div className={["col-md-12"].join(" ")}>
            <div className={styles.productImageContainer}>
              <img
                className={styles.productImg}
                src={this.props.imagePath}
              ></img>
            </div>
            <div>
              PP/HDPE woven sacks, also known as polypropylene (PP) or
              high-density polyethylene (HDPE) woven bags, are a type of
              packaging material commonly used for various industrial and
              agricultural purposes. These sacks are made from woven
              polypropylene or high-density polyethylene fabric, which is a
              strong and durable material that provides excellent protection to
              the contents.
            </div>
            <br />
            <div>
              Here are some key characteristics and details about PP/HDPE woven
              sacks:
            </div>
            <ol>
              <li>
                <b>Strength and Durability:</b> PP/HDPE woven sacks are known
                for their high tensile strength, tear resistance, and
                durability, making them suitable for heavy-duty applications.
              </li>
              <li>
                <b>UV Resistance:</b> Many of these sacks are treated to resist
                UV radiation, protecting contents from sunlight exposure during
                outdoor storage.
              </li>
              <li>
                <b>Cost-Effective:</b> They offer a cost-effective packaging
                solution while maintaining product integrity.
              </li>
              <li>
                <b>Customizable:</b> These sacks can be customized with printing
                and labeling for branding and product information.
              </li>
              <li>
                <b>Breathability:</b> They allow for the aeration of certain
                products to prevent moisture buildup and maintain product
                freshness.
              </li>
              <li>
                <b>Recyclable:</b> Both PP and HDPE materials are recyclable,
                contributing to environmental sustainability.
              </li>
              <li>
                <b>Wide Applications:</b> PP/HDPE woven sacks are used in
                agriculture, construction, chemicals, and various industries for
                bulk packaging of products like grains, fertilizers, and cement.
              </li>
            </ol>
            <div>
              In summary, PP/HDPE woven sacks are versatile and reliable
              packaging materials widely used in various industries for their
              durability, cost-effectiveness, and protective properties. They
              play a crucial role in safeguarding and transporting a wide range
              of products, from agricultural goods to industrial materials.
            </div>
          </div>
        </div>
      </>
    );
  }
}
