import { IProductPagesProps } from "./IProductPagesProps";
import { IProductPagesState } from "./IProductPagesState";
import styles from "./ProductPages.module.scss";
import { Component } from "react";

export default class GarbageBag extends Component<
  IProductPagesProps,
  IProductPagesState
> {
  constructor(props: any) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <>
        <div className={["row", styles.container].join(" ")}>
          <div className={["col-md-12"].join(" ")}>
            <div className={styles.productImageContainer}>
              <img
                className={styles.productImg}
                src={this.props.imagePath}
              ></img>
            </div>
            <div>
              Garbage bags, also known as trash bags or refuse sacks, are
              commonly used disposable bags designed for the collection and
              disposal of household and commercial waste. They come in various
              sizes, thicknesses, and materials, each with its own set of
              characteristics.
            </div>
            <br />
            <div>
              <b>Characteristics:</b>
            </div>
            <ol>
              <li>
                <b>Material:</b> Garbage bags are typically made from plastic
                materials such as polyethylene (PE) or polypropylene (PP). The
                choice of material determines the bag's strength, durability,
                and environmental impact.
              </li>
              <li>
                <b>Strength:</b> Garbage bags come in different thicknesses or
                gauges, ranging from lightweight to heavy-duty. Thicker bags are
                more durable and can handle heavier loads without tearing.
              </li>
              <li>
                <b>Capacity:</b> Garbage bags are available in various sizes and
                capacities, from small kitchen-sized bags to large industrial
                bags capable of holding substantial amounts of waste.
              </li>
              <li>
                <b>Closure Mechanism:</b> Many garbage bags feature drawstrings,
                twist ties, or flaps for easy sealing and closure, preventing
                odors and spills.
              </li>
              <li>
                <b>Color:</b> They come in various colors, with black and white
                being common choices. Some colors may be used to differentiate
                between different types of waste, such as recycling, compost, or
                general trash.
              </li>
              <li>
                <b>Odor Control:</b> Some garbage bags are designed with
                odor-blocking technology or scented options to help contain
                unpleasant smells.
              </li>
              <li>
                <b>Liners:</b> Garbage bags can be used as liners for trash cans
                or bins, providing a convenient and hygienic way to manage
                waste.
              </li>
              <li>
                <b>Environmental Impact:</b> There are eco-friendly garbage bags
                made from biodegradable or compostable materials, which are less
                harmful to the environment compared to traditional plastic bags.
              </li>
              <li>
                <b>Versatility:</b> Garbage bags are used not only for waste
                disposal but also for various other purposes, such as storage,
                moving, and organizing items.
              </li>
              <li>
                <b>Convenience:</b> They provide a convenient and hygienic way
                to collect and transport waste, reducing the need for direct
                contact with trash.
              </li>
            </ol>
            <div>
              Garbage bags are an essential part of waste management and
              sanitation, providing a clean and efficient means of collecting,
              containing, and disposing of various types of waste. The choice of
              garbage bag characteristics depends on the specific application
              and waste disposal needs.
            </div>
          </div>
        </div>
      </>
    );
  }
}
