import { Component } from "react";
import { Modal } from "@fluentui/react/lib/Modal";
import { ISBGModalProps } from "./ISBGModalProps";
import { ISBGModalState } from "./ISBGModalState";
import { getModalClassNames } from "./SBGModal.classNames";
import styles from "./SBGModal.module.scss";

export default class SBGModal extends Component<
  ISBGModalProps,
  ISBGModalState
> {
  constructor(props: ISBGModalProps) {
    super(props);

    this.state = {};
  }

  render() {
    const { body, header, headerTitle, cancelButton } = getModalClassNames();
    return (
      <>
        <Modal
          titleAriaId={this.props.titleId}
          isOpen={this.props.isOpen}
          onDismiss={this.props.onDismiss}
          isBlocking={this.props.isBlocking}
          containerClassName={this.props.containerClassName}
        >
          <div className={header}>
            <span className={headerTitle} id={this.props.titleId}>
              {this.props.modalHeader}
            </span>
            <img
              src={"/images/Cancel.svg"}
              className={cancelButton}
              onClick={this.props.onDismiss}
              alt="CancelIcon"
            />
          </div>
          <div className={body}>
            <div className={styles.childContainer}>
              {this.props.childComponent}
            </div>
          </div>
        </Modal>
      </>
    );
  }
}
